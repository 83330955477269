// extracted by mini-css-extract-plugin
export const root = "hero-banner-module--root--hk13Z";
export const whiteGradientBackground = "hero-banner-module--whiteGradientBackground--3XD88";
export const particles = "hero-banner-module--particles--2L_W_";
export const container = "hero-banner-module--container--2prZO";
export const topRow = "hero-banner-module--topRow--3U0Bs";
export const title = "hero-banner-module--title--F3E3r typography-module--responsiveTitle1--2aak3";
export const subtitle = "hero-banner-module--subtitle--GReTK typography-module--responsiveTitle2--3J_AG";
export const loaderContainer = "hero-banner-module--loaderContainer--2gJ97";
export const visible = "hero-banner-module--visible--bE2z5";
export const bottomRow = "hero-banner-module--bottomRow--e1-2I";
export const myStuff = "hero-banner-module--myStuff--NfsmO";
export const itemContainer = "hero-banner-module--itemContainer--2W_fO";
export const deskLamp = "hero-banner-module--deskLamp--306n8";
export const coffee = "hero-banner-module--coffee--2xBPm";
export const screen = "hero-banner-module--screen--11w1_";
export const screenInner = "hero-banner-module--screenInner--3NU9g";
export const screenTrack = "hero-banner-module--screenTrack--5Q71b";
export const infiniteSlideLandscape = "hero-banner-module--infinite-slide-landscape--3AyMz";
export const code = "hero-banner-module--code--2AfeS";
export const frame = "hero-banner-module--frame--2a3UJ";
export const stand = "hero-banner-module--stand--ABT-8";
export const standNeck = "hero-banner-module--standNeck--1J7MB";
export const standBottom = "hero-banner-module--standBottom--1AR3k";
export const socialList = "hero-banner-module--socialList--1XST9";
export const socialItem = "hero-banner-module--socialItem--n29yj";
export const colorBlack = "hero-banner-module--colorBlack--11WZw";
export const colorWhite = "hero-banner-module--colorWhite--1eUJH";
export const backgroundColorBlack = "hero-banner-module--backgroundColorBlack--9SRCi";
export const borderColorBlack = "hero-banner-module--borderColorBlack--XWPBu";
export const borderColorDarkGray = "hero-banner-module--borderColorDarkGray--1MjRQ";
export const infiniteSlidePortrait = "hero-banner-module--infinite-slide-portrait--pf4PY";